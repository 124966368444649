
import { readUserProfile } from '@/store/main/getters';
import { dispatchRequestBuyOneClick, dispatchRequestProductPurchase } from '@/store/products/actions';
import { readProductsInvoice } from '@/store/products/getters';
import { commitSetInvoiceDetail, commitSetProductsInvoice } from '@/store/products/mutations';
import { getLocalSessionId } from '@/utils';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { mask } from 'vue-the-mask';

@Component({
  directives: {mask},
  components: {
  },
})

export default class BuyOneClick extends Vue {
  @Prop({required: true}) public readonly showSidebar!: boolean;
  @Prop({required: false, default: null}) public readonly productVariant!: number;
  public buy_one_click = {
    phone: '',
  };
  public formError: boolean = false;
  public loadingState: any = {
    submit: false,
  };

  public mounted() {
    const userProfile = readUserProfile(this.$store);
    if (userProfile) {
      this.buy_one_click.phone = userProfile.phone;
    }
  }

  public async handleSubmit() {
    this.formError = false;
    if (!this.buy_one_click.phone) {
      this.formError = true;
      return false;
    }
    this.loadingState.submit = true;
    if (this.productVariant) {
      const response = await dispatchRequestProductPurchase(
        this.$store, {
          product_property: this.productVariant,
          quantity: 1,
          invoice: null,
          one_click: true,
          phone: this.buy_one_click.phone,
          anon_session_id: getLocalSessionId(),
        },
      );
    } else if (this.productsInvoice) {
      const response = await dispatchRequestBuyOneClick(
         this.$store, {
          invoice: this.productsInvoice,
          phone: this.buy_one_click.phone,
        },
      )
    }
    this.loadingState.submit = false;
    this.closeSidebar();
    this.$toast.success(this.$t('Заказ отправлен в обработку').toString());
    commitSetProductsInvoice(this.$store, null);
    commitSetInvoiceDetail(this.$store, null);
    this.$router.push('/page/thank_you');
  }

  public closeSidebar() {
    this.$emit('close-sidebar');
  }

  get productsInvoice() {
    return readProductsInvoice(this.$store);
  }
}
